<template>
  <a-layout class="results">
    <TitleBreadcrumb />
    <div class="content-base container">
      <ResultList :show-description="!!$route?.params?.lottery" />
    </div>
  </a-layout>
</template>
<script>
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import ResultList from "@/components/ResultList.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
export default {
  components: { TitleBreadcrumb, ResultList },
  name: "Results",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.results.title),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.results.title,
      },
    ],
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.results {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }
  .content-base {
    padding-top: 60px;
    padding-bottom: 0;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
  }
}
</style>
